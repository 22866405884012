import axios from '../axiosInstance';
import { getUserGroupId } from 'utils/utils';
import { mapToAddPropertyRequestBody, mapToEditPropertyRequestBody } from './mapper';

/*
 * |---------------------------------------------------------------------------------------------------
 * | Properties API
 * | getProperties - getProperty - addProperty - updateProperty - deleteProperty - getPropertyRelated
 * |---------------------------------------------------------------------------------------------------
 */

export const getProperties = ({ queryKey }) => {
  const [, recordType] = queryKey;

  return axios.get(
    `/api/v1/property-app/properties/${getUserGroupId()}?filter[recordType]=${recordType}`,
  );
};

export const getProperty = (propertyId) =>
  axios.get(`/api/v1/property-app/property/show/${propertyId}/${getUserGroupId()}`);

export const addProperty = (payload) => {
  return axios.post(
    `/api/v1/property-app/property/store`,
    mapToAddPropertyRequestBody(payload, getUserGroupId()),
  );
};

export const updateProperty = (payload) =>
  axios.post(
    `/api/v1/property-app/property/update`,
    mapToEditPropertyRequestBody(payload, getUserGroupId()),
  );

export const deleteProperty = (propertyIds) => {
  return axios.post(`/api/v1/property-app/property/destroy`, {
    user_group_id: getUserGroupId(),
    property_ids: propertyIds,
  });
};

export const getPropertyRelated = (propertyId) =>
  axios.get(`/api/v1/property-app/property/related/${propertyId}/${getUserGroupId()}`);



export const linkGlAccountsToProperty = async (payload) => {
  try {
    const response = await axios.post(
      `https://property.thelocus.xyz/api/v1/property/link/`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return response; // Ensure that the response is what you expect
  } catch (error) {
    // Improved error handling
    if (error.response) {
      // The request was made, and the server responded with a status code that falls out of the range of 2xx
      console.error('Server responded with an error:', error.response.data);
      throw error.response.data; // Throwing the error data to handle it further upstream
    } else if (error.request) {
      // The request was made, but no response was received
      console.error('No response received from server:', error.request);
      throw new Error('No response received from server');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up the request:', error.message);
      throw new Error('Error setting up the request: ' + error.message);
    }
  }
};
export const getLinkedGlAccounts = async (propertyId) => {
  try {
    const response = await axios.get(`https://property.thelocus.xyz/api/v1/property/${propertyId}/linked-gl-accounts`);
    return response.linkedGLAccounts; // Adjust based on your backend response structure
  } catch (error) {
    console.error('Failed to fetch linked GL accounts:', error);
    throw error; // Rethrow the error for handling in the calling code
  }
};

export const deleteLinkedGlAccount = async (propertyId, glAccountId) => {
  try {
    const response = await axios.delete(`https://property.thelocus.xyz/api/v1/property/${propertyId}/linked-gl-accounts/${glAccountId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete linked GL account:', error);
    throw error;
  }
};


export const getProperty1 = (propertyId) =>
  axios.get(`/api/v1/property-app/property/show/${propertyId}/${getUserGroupId()}`);

/*
 * |-----------------------------------------------------------------------------------------------------
 * | Property Attachment API
 * | getPropertyAttachment - addPropertyAttachment - updatePropertyAttachment - deletePropertyAttachment
 * |-----------------------------------------------------------------------------------------------------
 */

export const getPropertyAttachment = (propertyId, page) =>
  axios.get(
    `/api/v1/property-app/property/attachment/${propertyId}/${getUserGroupId()}?page=${page}`,
  );

export const addPropertyAttachment = (payload) => {
  const formData = new FormData();
  Object.entries(payload.attachment).forEach(([key, value]) => {
    formData.append('attachments[' + key + ']', value);
  });

  formData.append('property_id', payload.property_id);
  formData.append('user_group_id', getUserGroupId());

  if (payload.attachment_type_id === '1')
    return axios.post(`/api/v2/property-app/properties/attachments/store`, formData);
  else return axios.post(`/api/v2/property-app/properties/public-images/store`, formData);
};

export const updatePropertyAttachment = (payload) => {
  return axios.post(`/api/v1/property-app/property/attachment/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const deletePropertyAttachment = (propertyAttachmentId) => {
  return axios.post(`/api/v1/property-app/property/attachment/destroy`, {
    user_group_id: getUserGroupId(),
    property_attachment_ids: [propertyAttachmentId],
  });
};

/*
 * |-----------------------------
 * | Property Public images API
 * | getPropertyPulicImages
 * |-----------------------------
 */

export const getPropertyPulicImages = (propertyId) =>
  axios.get(`/api/v1/property-app/property/public-images/${propertyId}/${getUserGroupId()}`);

/*
 * |---------------------------------------------------------------------------------------------------
 * | Property Ownership API
 * | getPropertyOwnerships - addPropertyOwnership - updatePropertyOwnership - deletePropertyOwnership
 * |---------------------------------------------------------------------------------------------------
 */

export const addPropertyOwnership = (payload) => {
  return axios.post(`/api/v1/property-app/property/ownership/store`, {
    user_group_id: getUserGroupId(),
    ...payload,
  });
};

export const updatePropertyOwnership = (payload) => {
  return axios.post(`/api/v1/property-app/property/ownership/update`, {
    user_group_id: getUserGroupId(),
    ...payload,
  });
};

export const deletePropertyOwnership = (propertyOwnershipId) => {
  return axios.post(`/api/v1/property-app/property/ownership/destroy`, {
    user_group_id: getUserGroupId(),
    property_ownership_ids: [propertyOwnershipId],
  });
};

/*
 * |--------------------------
 * | Property Amenity API
 * | addPropertyAmenity
 * |--------------------------
 */

export const addPropertyAmenity = (payload) => {
  return axios.post(`/api/v1/property-app/property/amenity/store`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

/*
 * |--------------------------------------------------------
 * | Property Rooms API
 * | addRoom  - updateRoom - deleteRoom
 * |--------------------------------------------------------
 */

export const addRoom = (payload) => {
  /**
   *  @param payload
   *    * (propertyId - title - surfaceSQM - roomStatusId)
   */
  return axios.post(`/api/v1/property-app/property/room/store`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const updateRoom = (payload) => {
  return axios.post(`/api/v1/property-app/property/room/update`, {
    ...payload,
    user_group_id: getUserGroupId(),
  });
};

export const deleteRoom = (roomId) => {
  return axios.post(`/api/v1/property-app/property/room/destroy`, {
    user_group_id: getUserGroupId(),
    room_id: roomId,
  });
};

/*
 * |--------------------------------------------------------
 * | Property Assets API
 * | addAsset - updateAsset - deleteAsset
 * |--------------------------------------------------------
 */

export const addAsset = (payload) => {
  return axios.post(`/api/v1/property-app/property/asset/store`, {
    user_group_id: getUserGroupId(),
    ...payload,
  });
};

export const updateAsset = (payload) => {
  return axios.post(`/api/v1/property-app/property/asset/update`, {
    user_group_id: getUserGroupId(),
    ...payload,
  });
};

export const deleteAsset = (propertyAssetId) => {
  return axios.post(`/api/v1/property-app/property/asset/destroy`, {
    user_group_id: getUserGroupId(),
    property_asset_id: propertyAssetId,
  });
};

export const getPropertyDropdownValues = () =>
  axios.get(`/api/v2/property-app/property/dropdown-values/${getUserGroupId()}`);

export const getPropertyInsights = (propertyId) =>
  axios.get(`/api/v2/property-app/property/show/insights/${propertyId}/${getUserGroupId()}`);

export const exportProperties = (payload) => {
  return axios.post(`/api/v2/property-app/property/export`, {
    user_group_id: getUserGroupId(),
    type: payload.type,
    property_ids: payload.propertyIds,
  });
};

/*
 * |---------------------------------------------------------------------------------------------------
 * | Property Notify Tenant API
 * |---------------------------------------------------------------------------------------------------
 */

export const notifyTenantDropdownValues = (propertyId) =>
  axios.get(
    `/api/v2/property-app/properties/notification/dropdown-values/${propertyId}/${getUserGroupId()}`,
  );

export const sendNotification = (payload) => {
  return axios.post(`/api/v2/property-app/properties/notification/`, {
    user_group_id: getUserGroupId(),
    ...payload,
  });
};

export const getPropertyCommunications = (propertyId) =>
  axios.get(`/api/v2/property-app/property/communications/${propertyId}/${getUserGroupId()}`);

export const googleMapsAutoComplete = (query) => {
  return axios.get(
    `/api/v2/address-app/address/google-maps/auto-complete/${getUserGroupId()}?input=${query}`,
  );
};

//-------------------------------------- GL Account API's ---------------------------------------------------------\\
export const getAllGLAccounts = ({ queryKey }) => {
  const [, propertyId, searchKey] = queryKey;
  return axios.get(
    `/api/v2/property-app/accounting/gl-accounts/${propertyId}/${getUserGroupId()}?filter[search]=${searchKey}`,
  );
};

export const addGLAccount = (payload) => {
  return axios.post(`/api/v2/property-app/accounting/gl-accounts/store`, {
    user_group_id: getUserGroupId(),
    parent_reference_id: payload.parent_reference_id,
    parent_reference_type: payload.parent_reference_type,
    title: payload.title,
    code: payload.code,
    description: payload.description,
    reference_id: payload.referenceRecord,
    reference_type: payload.referenceType,
    vat_percentage: payload.VATPercentage,
    vat_enabled: payload.enableVAT,
    balance_side: payload.balanceSide,
    currency_id: payload.currency,
    sub_type_id: payload.subType,
    type: payload.type,
  });
};

export const updateGLAccount = (payload) => {
  return axios.post(`/api/v2/property-app/accounting/gl-accounts/update`, {
    user_group_id: getUserGroupId(),
    gl_account_id: payload.id,
    title: payload.title,
    code: payload.code,
    description: payload.description,
  });
};

export const deleteGLAccount = (accountId) => {
  return axios.post(`/api/v2/property-app/accounting/gl-accounts/destroy`, {
    user_group_id: getUserGroupId(),
    gl_account_id: accountId,
  });
};

export const getSubTypes = () =>
  axios.get(`/api/v2/property-app/accounting/sub-types/${getUserGroupId()}`);

export const addSubType = (payload) => {
  return axios.post(`/api/v2/property-app/accounting/sub-types/store`, {
    user_group_id: getUserGroupId(),
    name: payload.name,
    type: payload.type,
    description: payload.description,
  });
};

export const updateSubType = (payload) => {
  return axios.post(`/api/v2/property-app/accounting/sub-types/update`, {
    user_group_id: getUserGroupId(),
    ...payload,
  });
};

export const deleteSubType = (subTypeId) => {
  return axios.post(`/api/v2/property-app/accounting/sub-types/destroy`, {
    user_group_id: getUserGroupId(),
    sub_type_id: subTypeId,
  });
};

export const getGlAccountsDropdown = () =>
  axios.get(`/api/v2/property-app/accounting/dropdown/gl-accounts/${getUserGroupId()}`);

export const exportGlAccounts = () => {
  return axios.post(`/api/v2/property-app/accounting/gl-accounts/export`, {
    user_group_id: getUserGroupId(),
    type: '',
    gl_account_id: [],
  });
};


export const getKostenDrager = async () => {
  try {
    const response = await axios.get(`https://accounting-v2.thelocus.xyz/api/v2/gl-accounts/kosten-drager/${getUserGroupId()}`);
    return response; // Adjust based on your backend response structure
  } catch (error) {
    console.error('Failed to fetch Kosten Drager:', error);
    throw error; // Rethrow the error for handling in the calling code
  }
};

export const getGLTransaction = async (glAccountCodes, financialYear, financialPeriodFrom, financialPeriodTo) => {
  try {
    const userGroupId = getUserGroupId();

    // Construct the query parameters
    const params = new URLSearchParams();
    glAccountCodes.forEach(code => params.append('gl_account_ids[]', code));

    console.log('params', params.toString());

    const response = await axios.get(`https://accounting-v2.thelocus.xyz/api/v2/gl-accounts/transactions/${userGroupId}/${financialYear}/${financialPeriodFrom}/${financialPeriodTo}?${params.toString()}`);
    return response; // Adjust based on your backend response structure
  } catch (error) {
    console.error('Failed to fetch GL Transactions:', error);
    throw error; // Rethrow the error for handling in the calling code
  }
};