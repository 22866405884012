import React from 'react';
import { truncate } from 'utils/utils';
import { TagsRenderer } from 'components';
import { Select, MenuItem, CircularProgress } from '@mui/material';

export const StyledSelect = (props) => {
  // Ensure value is always defined
  const value = props.value !== undefined ? props.value : (props.multiple ? [] : '');

  return (
    <Select
      sx={{
        borderRadius: props.borderRadius ? props.borderRadius : '16px',
        boxShadow: props.error && '0px 0px 0px 3px rgb(234 51 35 / 20%)',
        fontSize: 14,
        width: props.width ? props.width : '100%',
        height: props.height ? props.height : '56px',
      }}
      variant="outlined"
      multiple={props.multiple}
      disabled={props.disabled}
      name={props.name}
      value={value}
      placeholder={props.placeholder}
      error={props.error}
      onChange={props.onChange}
      disableUnderline
      displayEmpty
      renderValue={(selected) => {
        if (props.multiple) {
          if (!selected || selected.length === 0) return props.placeholder;
          const selectedTagsValues = props.itemsList
            .filter((item) => selected.includes(item.id))
            .map((item) => item.name);
          return <TagsRenderer tags={selectedTagsValues} />;
        } else {
          if (!selected) return props.placeholder;
          const isValuePresent = props.itemsList?.find((item) => String(item.id) === String(selected));
          if (isValuePresent) return truncate(isValuePresent.name);
          return props.placeholder.concat(': Select a valid option');
        }
      }}
    >
      {props?.isLoading && (
        <div className="flex items-center justify-center">
          <CircularProgress color="primary" sx={{ color: '#EA3323' }} size={25} />
        </div>
      )}

      {props?.itemsList?.map((list) => (
        <MenuItem disabled={list.disabled} key={list.id} value={list.id}>
          {list.name}
        </MenuItem>
      ))}
    </Select>
  );
};