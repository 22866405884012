import { Routes, Route, useLocation, Navigate } from 'react-router-dom';

import {
  DashboardApiFunctions,
  contactApiFunctions,
  propertyApiFunctions,
  leadsApiFunctions,
  listingApiFunctions,
  repairsApiFunctions,
  transactionApiFunctions,
  settingsApiFunctions,
  closingsApiFunctions,
  AuthApiFunctions,
} from 'apiManager';

import withApiFunctions from 'HOC/withApiFunctions';

import * as LazyComponents from './lazyComponents';
import { PrivateRoutes } from './privateRoutes';

import AccountingCallBack from 'pages/settings/accounting/accountingCallBack';
import { ConfirmAccount, Register } from 'pages';
import SubscriptionPage from '../pages/login/subscriptionPage';
// import { PropertyAccounting } from 'pages/propertyOverview';

const EnhancedRegisterComponent = withApiFunctions(Register, AuthApiFunctions);

const EnhancedSubscriptionPageComponent = withApiFunctions(
  LazyComponents.SubscriptionPage,
  AuthApiFunctions,
);

const EnhancedDashboardComponent = withApiFunctions(
  LazyComponents.Dashboard,
  DashboardApiFunctions,
);

const EnhancedContactsComponent = withApiFunctions(LazyComponents.Contacts, contactApiFunctions);
const EnhancedContactOverviewComponent = withApiFunctions(
  LazyComponents.ContactOverview,
  contactApiFunctions,
);
const EnhancedAttachmentsComponent = withApiFunctions(
  LazyComponents.ContactAttachments,
  contactApiFunctions,
);
const EnhancedContactRelatedComponent = withApiFunctions(
  LazyComponents.ContactRelated,
  contactApiFunctions,
);
const EnhancedContactFinancialsComponent = withApiFunctions(
  LazyComponents.Financials,
  contactApiFunctions,
);

const EnhancedPropertyComponent = withApiFunctions(LazyComponents.Properties, propertyApiFunctions);
const EnhancedPropertyOverviewComponent = withApiFunctions(
  LazyComponents.PropertyOverview,
  propertyApiFunctions,
);
const EnhancedGLAccountComponent = withApiFunctions(LazyComponents.GLAccount, propertyApiFunctions);
const EnhancedPropertyRelatedComponent = withApiFunctions(
  LazyComponents.PropertyRelated,
  propertyApiFunctions,
);
const EnhancedPropertyAttachmentsComponent = withApiFunctions(
  LazyComponents.PropertyAttachments,
  propertyApiFunctions,
);

const EnhancedPropertyPublicImagesComponent = withApiFunctions(
  LazyComponents.PropertyPublicImages,
  propertyApiFunctions,
);

const EnhancedPropertyServiceCostsComponent = withApiFunctions(
  LazyComponents.ServiceCosts,
  propertyApiFunctions,
);

const EnhancedListingsComponent = withApiFunctions(LazyComponents.Listings, listingApiFunctions);
const EnhancedListingOverviewComponent = withApiFunctions(
  LazyComponents.ListingOverview,
  listingApiFunctions,
);
const EnhancedListingOffersComponent = withApiFunctions(
  LazyComponents.ListingOffers,
  listingApiFunctions,
);
const EnhancedListingRelatedComponent = withApiFunctions(
  LazyComponents.ListingRelated,
  listingApiFunctions,
);
const EnhancedListingAttachmentsComponent = withApiFunctions(
  LazyComponents.ListingAttachments,
  listingApiFunctions,
);

const EnhancedLeadsComponent = withApiFunctions(LazyComponents.Leads, leadsApiFunctions);
const EnhancedLeadOverviewComponent = withApiFunctions(
  LazyComponents.LeadOverview,
  leadsApiFunctions,
);

const EnhancedRepairsComponent = withApiFunctions(LazyComponents.Repairs, repairsApiFunctions);
const EnhancedRepairOverviewComponent = withApiFunctions(
  LazyComponents.RepairOverview,
  repairsApiFunctions,
);
const EnhancedRepairAttachmentsComponent = withApiFunctions(
  LazyComponents.RepairAttachments,
  repairsApiFunctions,
);

const EnhancedInvoicesComponent = withApiFunctions(
  LazyComponents.Transactions,
  transactionApiFunctions,
);

const EnhancedTransactionOverviewComponent = withApiFunctions(
  LazyComponents.TransactionOverview,
  transactionApiFunctions,
);

const EnhancedTransactionDetailsComponent = withApiFunctions(
  LazyComponents.TransactionDetails,
  transactionApiFunctions,
);

const EnhancedTransactionPaymentsComponent = withApiFunctions(
  LazyComponents.TransactionPayments,
  transactionApiFunctions,
);

const EnhancedTransactionAttachmentsComponent = withApiFunctions(
  LazyComponents.TransactionAttachments,
  transactionApiFunctions,
);

const EnhancedSettingsProfileComponent = withApiFunctions(
  LazyComponents.ProfileSettings,
  settingsApiFunctions,
);

const EnhancedClosingsComponent = withApiFunctions(LazyComponents.Closings, closingsApiFunctions);
const EnhancedClosingOverviewComponent = withApiFunctions(
  LazyComponents.ClosingOverview,
  closingsApiFunctions,
);
const EnhancedClosingAttachmentComponent = withApiFunctions(
  LazyComponents.ClosingAttachments,
  closingsApiFunctions,
);
const EnhancedClosingDetailsComponent = withApiFunctions(
  LazyComponents.ClosingDetails,
  closingsApiFunctions,
);

const EnhancedBankIntegrationComponent = withApiFunctions(
  LazyComponents.BankIntegrationSettings,
  settingsApiFunctions,
);

const EnhancedUserGroupComponent = withApiFunctions(
  LazyComponents.UserGroupSettings,
  settingsApiFunctions,
);

const AppRoutes = () => {
  let location = useLocation();

  return (
    <Routes location={location}>
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<EnhancedDashboardComponent />} />

        <Route path="/contacts" element={<EnhancedContactsComponent />} />
        <Route path="/contact-overview/:contactId" element={<EnhancedContactOverviewComponent />}>
          <Route path="details" element={<LazyComponents.ContactDetails />} />
          <Route path="related" element={<EnhancedContactRelatedComponent />} />
          <Route path="financials" element={<EnhancedContactFinancialsComponent />} />
          <Route path="attachments" element={<EnhancedAttachmentsComponent />} />
        </Route>

        <Route path="/properties" element={<EnhancedPropertyComponent />} />
        <Route
          path="/property-overview/:propertyId/"
          element={<EnhancedPropertyOverviewComponent />}
        >
          <Route path="details" element={<LazyComponents.PropertyDetails />} />
          <Route path="related" element={<EnhancedPropertyRelatedComponent />} />
          <Route path="communication" element={<LazyComponents.Communication />} />
          <Route path="gl-account" element={<EnhancedGLAccountComponent />} />
          {/* <Route path="accounting" element={<PropertyAccounting />} /> */}
          <Route path="attachments" element={<EnhancedPropertyAttachmentsComponent />} />
          <Route path="public-images" element={<EnhancedPropertyPublicImagesComponent />} />
          <Route path="service-costs" element={<EnhancedPropertyServiceCostsComponent />} />
        </Route>

        <Route path="/repairs" element={<EnhancedRepairsComponent />} />
        <Route path="/repair-overview/:repairId/" element={<EnhancedRepairOverviewComponent />}>
          <Route path="details" element={<LazyComponents.RepairDetails />} />
          <Route path="attachments" element={<EnhancedRepairAttachmentsComponent />} />
        </Route>

        <Route path="/listings" element={<EnhancedListingsComponent />} />
        <Route path="/listing-overview/:listingId/" element={<EnhancedListingOverviewComponent />}>
          <Route path="details" element={<LazyComponents.ListingDetails />} />
          <Route path="offers" element={<EnhancedListingOffersComponent />} />
          <Route path="related" element={<EnhancedListingRelatedComponent />} />
          <Route path="attachments" element={<EnhancedListingAttachmentsComponent />} />
          <Route path="public-images" element={<LazyComponents.ListingPublicImages />} />
        </Route>

        <Route path="/leads" element={<EnhancedLeadsComponent />} />
        <Route path="/lead-overview/:leadId/" element={<EnhancedLeadOverviewComponent />}>
          <Route path="details" element={<LazyComponents.LeadDetails />} />
        </Route>

        <Route path="/closings" element={<EnhancedClosingsComponent />} />
        <Route path="/closing-overview/:closingId/" element={<EnhancedClosingOverviewComponent />}>
          <Route path="details" element={<EnhancedClosingDetailsComponent />} />
          <Route path="related" element={<LazyComponents.ClosingRelated />} />
          <Route path="attachments" element={<EnhancedClosingAttachmentComponent />} />
        </Route>

        <Route path="/reports" element={<LazyComponents.Reports />} />

        <Route path="/transactions" element={<EnhancedInvoicesComponent />} />
        <Route
          path="/transaction-overview/:transactionId/"
          element={<EnhancedTransactionOverviewComponent />}
        >
          <Route path="details" element={<EnhancedTransactionDetailsComponent />} />
          <Route path="payments" element={<EnhancedTransactionPaymentsComponent />} />
          <Route path="attachments" element={<EnhancedTransactionAttachmentsComponent />} />
        </Route>

        <Route path="/settings" element={<LazyComponents.Settings />}>
          <Route path="profile" element={<EnhancedSettingsProfileComponent />} />
          <Route path="user-group" element={<EnhancedUserGroupComponent />} />
          <Route path="bank-integration" element={<EnhancedBankIntegrationComponent />} />
          <Route path="acounting" element={<LazyComponents.AccountingSettings />}>
            <Route path="oauth2/callback" element={<AccountingCallBack />} />
          </Route>
        </Route>
      </Route>

      <Route path="/login" element={<LazyComponents.Login />} />
      <Route path="/register" element={<EnhancedRegisterComponent />} />
      <Route path="/subscription" element={<EnhancedSubscriptionPageComponent />} />
      <Route path="/confirm-account" element={<ConfirmAccount />} />
      <Route path="/forgot-password" element={<LazyComponents.ForgotPassword />} />
      <Route path="/set-new-password" element={<LazyComponents.SetNewPassword />} />
      <Route path="/otp" element={<LazyComponents.Otp />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
