import React, { useEffect } from 'react';
import { truncate } from 'utils/utils';
import { TagsRenderer } from 'components';
import { Select, MenuItem, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export const StyledSearchableSelect = (props) => {
    const selectedValues = props.value || [];

    const handleChange = (event, newValue) => {
        if (props.multiple) {
            props.onChange(newValue); // Pass the array of selected objects to the parent component.
        } else {
            props.onChange(newValue || null); // Pass the single selected object or null.
        }
    };


    return (
        <Autocomplete
            name={props.name}
            value={selectedValues}
            multiple={props.multiple}
            options={props.itemsList}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: props.borderRadius ? props.borderRadius : '16px',
                        },
                        boxShadow: props.error && '0px 0px 0px 3px rgb(234 51 35 / 20%)',
                        fontSize: 14,
                        width: props.width ? props.width : '100%',
                        height: props.height ? props.height : '56px',
                    }}
                    error={props.error}
                    variant="outlined"
                    placeholder={props.placeholder}
                    value={props.value}
                />
            )}
        />
    );
};