import { lazy } from 'react';

export const Login = lazy(() => import('pages/login/login'));
export const Register = lazy(() => import('pages/signUp/register'));
// export const ConfirmAccount = lazy(() => import('pages/confirmAccount/confirmAccount'));
export const SubscriptionPage = lazy(() => import('pages/login/subscriptionPage'));
export const ForgotPassword = lazy(() => import('pages/forgotPassword/forgotPassword'));
export const SetNewPassword = lazy(() => import('pages/setNewPassword/setNewPassword'));
export const Otp = lazy(() => import('pages/otp/otp'));
export const Dashboard = lazy(() => import('pages/dashboard/dashboard'));
export const Contacts = lazy(() => import('pages/contacts/contacts'));
export const Properties = lazy(() => import('pages/properties/properties'));
export const Listings = lazy(() => import('pages/listings/listings'));
export const Leads = lazy(() => import('pages/leads/leads'));
export const Closings = lazy(() => import('pages/closings/closings'));
export const Transactions = lazy(() => import('pages/transactions/transactions'));
export const Repairs = lazy(() => import('pages/repairs/repairs'));
export const Settings = lazy(() => import('pages/settings/settings'));
export const Reports = lazy(() => import('pages/reports/reports'));

//  overview pages
export const ContactOverview = lazy(() => import('pages/contactOverview/contactOverview'));
export const PropertyOverview = lazy(() => import('pages/propertyOverview/propertyOverview'));
export const ListingOverview = lazy(() => import('pages/listingOverview/listingOverview'));
export const LeadOverview = lazy(() => import('pages/leadOverview/leadOverview'));
export const ClosingOverview = lazy(() => import('pages/closingOverview/closingOverview'));
export const TransactionOverview = lazy(() => import('pages/transactionOverview/transactionOverview'));
export const RepairOverview = lazy(() => import('pages/repairOverview/repairOverview'));

//  contacts sub routes
export const ContactDetails = lazy(() => import('pages/contactOverview/details'));
export const ContactRelated = lazy(() => import('pages/contactOverview/related'));
export const ContactAttachments = lazy(() => import('pages/contactOverview/attachments'));
export const Financials = lazy(() => import('pages/contactOverview/financials'));

//  properties sub routes
export const PropertyDetails = lazy(() => import('pages/propertyOverview/details'));
export const PropertyRelated = lazy(() => import('pages/propertyOverview/related'));
export const PropertyAttachments = lazy(() => import('pages/propertyOverview/attachments'));
export const PropertyPublicImages = lazy(() => import('pages/propertyOverview/publicImages'));
export const Communication = lazy(() => import('pages/propertyOverview/communication'));
export const GLAccount = lazy(() => import('pages/propertyOverview/glAccount'));
export const ServiceCosts = lazy(() => import('pages/propertyOverview/serviceCosts'));

//  listings sub routes
export const ListingDetails = lazy(() => import('pages/listingOverview/details'));
export const ListingOffers = lazy(() => import('pages/listingOverview/offers'));
export const ListingRelated = lazy(() => import('pages/listingOverview/related'));
export const ListingAttachments = lazy(() => import('pages/listingOverview/attachments'));
export const ListingPublicImages = lazy(() => import('pages/listingOverview/publicImages'));

export const LeadDetails = lazy(() => import('pages/leadOverview/details'));

//  closing sub routes
export const ClosingDetails = lazy(() => import('pages/closingOverview/details'));
export const ClosingRelated = lazy(() => import('pages/closingOverview/related'));
export const ClosingAttachments = lazy(() => import('pages/closingOverview/attachments'));

//  invoice sub routes
export const TransactionDetails = lazy(() => import('pages/transactionOverview/details'));
export const TransactionAttachments = lazy(() => import('pages/transactionOverview/attachments'));
export const TransactionPayments = lazy(() => import('pages/transactionOverview/payments'));

//  repair sub routes
export const RepairDetails = lazy(() => import('pages/repairOverview/details'));
export const RepairAttachments = lazy(() => import('pages/repairOverview/attachments'));

//  settings sub routes
export const ProfileSettings = lazy(() => import('pages/settings/profile'));
export const UserGroupSettings = lazy(() => import('pages/settings/userGroups'));
export const BankIntegrationSettings = lazy(() => import('pages/settings/bankIntegration'));
export const AccountingSettings = lazy(() => import('pages/settings/accounting'));
