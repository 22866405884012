import React from 'react';
import moment from 'moment/moment';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ErrorMessage, Form, Formik } from 'formik';
import { useIsMutating, useMutation, useQuery, useQueryClient } from 'react-query';
import { LocusContextProvider } from 'hooks/useLocusContext';
import { addClosing, addClosingPaymentRange, getClosingDropdownData } from 'apis/closings/closings';
import { addListing } from 'apis/listings/listings';
import { closingDialogSteps } from 'utils/constants';

import { ConfirmDialog, DatePicker, StyledDialog, StyledInput, StyledSelect } from 'components';
import { ClosingDetailsDialog } from './closingDialogComponents/closingDetailsDialog';

import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, DialogContent } from '@mui/material';

import backArrow from 'assets/backArrow.svg';
import alertIcon from 'assets/redAlert.svg';
import alertCross from 'assets/alertCross.svg';
import listingButtonIcon from 'assets/listingButtonIcon.svg';
import removeIcon from 'assets/removeIcon.svg';
import { useTranslation } from 'react-i18next';

const newClosingSchema = yup.object().shape({
  listing: yup
    .string()
    .typeError('listing field is required')
    .required('listing field is required'),
  tenant: yup.string().typeError('tenant field is required').required('tenant field is required'),
  closingType: yup
    .string()
    .typeError('closing type field is required')
    .required('closing type field is required'),
  offeredAmount: yup
    .string()
    .typeError('offered amount field is required')
    .required('offered amount field is required'),
  closingAmount: yup
    .string()
    .typeError('closing amount field is required')
    .required('closing amount field is required'),
  effectiveFrom: yup
    .date()
    .typeError('effective from field is required')
    .required('effective from field is required'),
  effectiveTo: yup
    .date()
    .typeError('effective from field is required')
    .min(yup.ref('effectiveFrom'), 'To date should be greater than From date')
    .nullable(),
  status: yup.string().typeError('status field is required').required('status field is required'),
  closingTypeCategory: yup
    .string()
    .typeError('closing type category field is required')
    .required('closing type category field is required'),
  glAccount: yup.string(),
});

const newClosingFromPropertySchema = yup.object().shape({
  tenant: yup.string().typeError('tenant field is required').required('tenant field is required'),
  closingType: yup
    .string()
    .typeError('closing type field is required')
    .required('closing type field is required'),
  offeredAmount: yup
    .string()
    .typeError('Input must be a number')
    .required('offered amount field is required'),
  closingAmount: yup
    .string()
    .typeError('Input must be a number')
    .required('closing amount field is required'),
  effectiveFrom: yup
    .date()
    .typeError('effective from field is required')
    .required('effective from field is required'),
  effectiveTo: yup
    .date()
    .typeError('effective from field is required')
    .min(yup.ref('effectiveFrom'), 'To date should be greater than From date')
    .nullable(),
  status: yup.string().typeError('status field is required').required('status field is required'),
  closingTypeCategory: yup
    .string()
    .typeError('closing type category field is required')
    .required('closing type category field is required'),
  listingTypeCategory: yup
    .string()
    .typeError('listing type category field is required')
    .required('listing type category field is required'),
});

const paymentRangeSchema = yup.object().shape({
  from: yup
    .string()
    .typeError('effective from field is required')
    .required('todo field is required'),
  to: yup
    .date()
    .typeError('effective from field is required')
    // .min(yup.ref('from'), 'to date should be greater than from date')
    .required('due date field is required'),
  amount: yup.string().typeError('Input must be a number').required('Amount field is required'),
  paymentFrequency: yup.string().required('status field is required'),
});

export const ClosingDialog = ({ openDialog, onClose }) => {
  const { t } = useTranslation();
  const isDialogOpen = () => {
    if (openDialog?.dialogType === 'addNewClosing') return true;
    else if (openDialog?.dialogType === 'addNewPropertyClosing') return true;
    return false;
  };

  const columns = [
    { field: 'from', headerName: 'from' },
    { field: 'to', headerName: 'To' },
    { field: 'amount', headerName: 'Amount' },
    { field: 'payment_frequency_id', headerName: 'Payment Frequency' },
    { field: 'enable_indexation', headerName: 'Indexation' },
    { field: 'vat', headerName: 'VAT' },
    { field: 'indexation_month', headerName: 'Indexation Month' },
    { field: 'remove', headerName: '' },
  ];

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isMutating = useIsMutating();
  const formikFormRef = React.useRef();

  const [createdClosingData, setCreatedClosingData] = React.useState(null);
  const [indexation, setIndexation] = React.useState(false);
  const [paymentRangesArray, setPaymentRangesArray] = React.useState([]);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [APIResponse, setAPIResponse] = React.useState({ message: '', code: '' });
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');

  const { data, isLoading } = useQuery('closingDropdownData', getClosingDropdownData, {
    enabled: isDialogOpen(),
  });

  const { mutate, isLoading: addClosingLoading } = useMutation((payload) => addClosing(payload));
  const { mutate: addPaymentRangeMutation, isLoading: addPaymentRangeLoading } = useMutation(
    (payload) => addClosingPaymentRange(payload),
  );
  const { mutate: addListingMutation, isLoading: addListingLoading } = useMutation((payload) =>
    addListing(payload),
  );

  const handleAddPaymentRanges = (value) => {
    const newPaymentRangesArray = [...paymentRangesArray, value];
    setPaymentRangesArray(newPaymentRangesArray);
  };

  const handleRemovePaymentRanges = (index) => {
    const newPaymentRangesArray = paymentRangesArray.filter((item, i) => i !== index);
    setPaymentRangesArray(newPaymentRangesArray);
  };

  //data for adding closing for listing overview
  const { listingId, listingTypeId } = openDialog?.data || {};

  //data for adding closing for property overview
  const { propertyDetails } = openDialog?.data || {};

  const currentValidationSchema =
    currentStep === 0
      ? openDialog?.dialogType === 'addNewPropertyClosing'
        ? newClosingFromPropertySchema
        : newClosingSchema
      : paymentRangeSchema;
  const isLastStep = currentStep === closingDialogSteps.length - 1;

  const handleDecreaseStep = () => {
    return setCurrentStep(currentStep - 1);
  };

  const handleCloseDialog = () => {
    formikFormRef.current?.resetForm();
    setCurrentStep(0);
    onClose();
  };

  const renderTableCell = (col, row, index) => {
    switch (col.field) {
      case 'remove':
        return (
          <div className="flex flex-1 items-center gap-4 w-[20%]">
            <img
              className="hover:cursor-pointer"
              src={removeIcon}
              onClick={() => handleRemovePaymentRanges(index)}
            />
          </div>
        );
      case 'indexation_month':
        return (
          <TableData type={row[col.field]}>
            {data?.data.months.find((month) => month.id === row[col.field])?.name || ''}
          </TableData>
        );
      case 'payment_frequency_id':
        return (
          <TableData type={row[col.field]}>
            {data?.data.paymentFrequency.find(
              (paymentFrequency) => paymentFrequency.id === row[col.field],
            )?.name || ''}
          </TableData>
        );
      case 'enable_indexation':
        return (
          <TableData type={row[col.field]}>{row[col.field] ? 'Enabled' : 'Disabled'}</TableData>
        );
      case 'vat':
        return (
          <TableData type={row[col.field]}>
            {row[col.field] ? row[col.field] + '%' : 'Disabled'}
          </TableData>
        );
      case 'amount':
        return <TableData type={row[col.field]}>{currencySymbol + row[col.field]}</TableData>;
      default:
        return <TableData type={row[col.field]}>{row[col.field]}</TableData>;
    }
  };
  return (
    <StyledDialog
      width="1064px"
      height="725px"
      padding="5px"
      open={isDialogOpen()}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {isLoading ? (
          <div className="grid place-content-center h-full">
            <CircularProgress color="error" />
          </div>
        ) : (
          <div className="flex gap-8 h-full">
            {currentStep !== 5 && (
              <div className="flex items-center gap-8 w-[25%]">
                <div className="relative flex flex-col justify-between h-[40%]">
                  <div className="absolute right-6 h-full border border-[#F6F4F4] z-10"></div>
                  {closingDialogSteps.map((step) => (
                    <div
                      key={step.stepIndex}
                      className="flex justify-center items-center gap-5 z-50"
                    >
                      <div
                        className={`w-full flex justify-end text-[16px] text-[#8E8E8E] ${currentStep === step.stepIndex && 'font-semibold text-black'
                          }`}
                      >
                        {step.stepName}
                      </div>
                      <div
                        className={`transition ease-in-out delay-150 p-4 ${currentStep === step.stepIndex ? 'bg-[#EA3323]' : 'bg-[#8E8E8E]'
                          } rounded-full`}
                      >
                        <img src={step.icon} />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="relative flex flex-col justify-between h-[40%]">
                  <div className="absolute h-full right-[7px] z-10 border border-[#F6F4F4]"></div>
                  {closingDialogSteps.map((step) => (
                    <div key={step.stepIndex} className="p-1 rounded-full flex bg-[#F6F4F4]">
                      <span
                        className={`${currentStep === step.stepIndex ? 'bg-[#EA3323]' : 'bg-[#8E8E8E]'
                          } w-2 h-2 z-50 rounded-full transition ease-in-out delay-150`}
                      ></span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <Formik
              innerRef={formikFormRef}
              enableReinitialize
              initialValues={{
                listing: listingId || propertyDetails?.propertyId || '',
                tenant: '',
                closingType: listingTypeId || '',
                offeredAmount: '',
                closingAmount: '',
                glAccount: '',
                effectiveFrom: null,
                effectiveTo: null,
                status: '',
                indexation: 0,
                indexationMonth: null,
                from: null,
                to: null,
                amount: '',
                paymentFrequency: '',
                usedIndexation: '',
                listingAskingPrice: '',
                closingTypeCategory: '',
                listingTypeCategory: '',
                vat: '',
                duePaymentFee: '',
                duePaymentFeeDays: '',
                rentingCosts: '',
                serviceChargesCosts: '',
                upChargesCosts: '',
                selectedOtherCosts: [],
              }}
              validationSchema={currentValidationSchema}
              onSubmit={(values, { resetForm }) => {
                if (currentStep === 0) {
                  if (openDialog?.dialogType === 'addNewPropertyClosing') {
                    addListingMutation(
                      {
                        listing_type_id: values.closingType,
                        available_from: moment(values.effectiveFrom).format('YYYY-MM-DD'),
                        available_to: moment(values.effectiveTo).format('YYYY-MM-DD'),
                        asking_price: values.listingAskingPrice,
                        property_id: propertyDetails?.propertyId,
                        listing_type_category_id: values.listingTypeCategory,
                      },
                      {
                        onSuccess: (data) => {
                          if (data.statusCode === 200) {
                            queryClient.invalidateQueries('listings');
                            mutate(
                              {
                                listing_id: data?.data?.listingId,
                                contact_id: values.tenant,
                                closing_type_id: values.closingType,
                                offered_amount: values.offeredAmount,
                                contract_start: moment(values.effectiveFrom).format('YYYY-MM-DD'),
                                contract_end:
                                  values.closingType === '2'
                                    ? null
                                    : moment(values.effectiveTo).format('YYYY-MM-DD'),
                                deal_amount: values.closingAmount,
                                closing_type_category_id: values.closingTypeCategory,
                                vat_applicable: values.vat,
                                progress_id: values.status,
                                accounting_reference: values.glAccount,
                                renting_costs: values.rentingCosts,
                                service_charges_costs: values.serviceChargesCosts,
                                upcharges_costs: values.upChargesCosts,
                                selected_other_costs: JSON.stringify(values.selectedOtherCosts),
                              },
                              {
                                onSuccess: (data) => {
                                  if (data.statusCode === 200) {
                                    queryClient.invalidateQueries('closings');
                                    queryClient.invalidateQueries('notificationsCount');
                                    setAPIResponse({ message: null, code: null });
                                    setCreatedClosingData(data?.data);
                                    setCurrentStep(currentStep + 1);
                                  } else if (data.statusCode === 403) {
                                    setAPIResponse({
                                      message: data.message,
                                      code: data.statusCode,
                                    });
                                  } else
                                    setAPIResponse({
                                      message: 'Server Error',
                                      code: data.statusCode,
                                    });
                                },
                              },
                            );
                          } else if (data.statusCode === 403)
                            setAPIResponse({ message: data.message, code: data.statusCode });
                          else setAPIResponse({ message: 'Server Error', code: data.statusCode });
                        },
                      },
                    );
                  } else {
                    mutate(
                      {
                        listing_id: values.listing,
                        contact_id: values.tenant,
                        closing_type_id: values.closingType,
                        offered_amount: values.offeredAmount,
                        contract_start: moment(values.effectiveFrom).format('YYYY-MM-DD'),
                        contract_end:
                          values.closingType === '2'
                            ? null
                            : moment(values.effectiveTo).format('YYYY-MM-DD'),
                        deal_amount: values.closingAmount,
                        closing_type_category_id: values.closingTypeCategory,
                        accounting_reference: values.glAccount,
                        progress_id: values.status,
                        vat_applicable: values.vat,
                        renting_costs: values.rentingCosts,
                        service_charges_costs: values.serviceChargesCosts,
                        upcharges_costs: values.upChargesCosts,
                        selected_other_costs: JSON.stringify(values.selectedOtherCosts),
                      },
                      {
                        onSuccess: (data) => {
                          if (data.statusCode === 200) {
                            queryClient.invalidateQueries('closings');
                            queryClient.invalidateQueries('notificationsCount');
                            setAPIResponse({ message: null, code: null });
                            setCreatedClosingData(data?.data);
                            setCurrentStep(currentStep + 1);
                          } else if (data.statusCode === 403) {
                            setAPIResponse({ message: data.message, code: data.statusCode });
                          } else setAPIResponse({ message: 'Server Error', code: data.statusCode });
                        },
                      },
                    );
                  }
                } else {
                  let payload = {
                    closing_id: createdClosingData.closingId,
                    payment_ranges: paymentRangesArray,
                  };
                  addPaymentRangeMutation(payload, {
                    onSuccess: (data) => {
                      if (data.statusCode === 200) {
                        queryClient.invalidateQueries('closingDetails');
                        setAPIResponse({ message: null, code: null });
                        navigate(`/closing-overview/${createdClosingData.closingId}/details`);
                        onClose();
                        resetForm();
                      } else if (data.statusCode === 403) {
                        setAPIResponse({ message: data.message, code: data.statusCode });
                      } else setAPIResponse({ message: 'Server Error', code: data.statusCode });
                    },
                  });
                }
              }}
            >
              {({ values, handleChange, errors, touched, setFieldValue, dirty }) => (
                <Form
                  className={`${currentStep === 5 ? 'w-full' : 'w-[75%]'
                    } flex flex-col gap-4 justify-between h-full p-2`}
                >
                  {APIResponse.message && (
                    <div className="flex justify-between items-center bg-[#FFF2F0] text-[#EA3323] p-3 rounded-lg">
                      <div className="flex gap-2">
                        <img src={alertIcon} />
                        <span>{APIResponse.message}</span>
                      </div>
                      <img
                        className="hover:cursor-pointer"
                        src={alertCross}
                        onClick={() => setAPIResponse({ message: '', code: '' })}
                      />
                    </div>
                  )}
                  <div className="overflow-auto">
                    <LocusContextProvider
                      dialogType={openDialog?.dialogType}
                      dropdownValues={data?.data}
                      // editData={editData?.data}
                      data={openDialog?.data}
                      paymentRangesArray={paymentRangesArray}
                      APIResponse={APIResponse}
                    >
                      {currentStep === 0 && <ClosingDetailsDialog />}
                      {currentStep === 1 && (
                        <div className="flex flex-col gap-2 p-1">
                          <div className="text-3xl font-bold">{t('payment_ranges')}</div>

                          <div className="flex gap-5">
                            <div className="flex flex-col gap-4 w-full">
                              <div>
                                <div className="text-sm pb-1">
                                  {t('from')}{' '}
                                  {errors['from'] && <span className="text-[#EA3323]">*</span>}
                                </div>
                                <DatePicker
                                  name="from"
                                  value={values.from}
                                  onChange={(value) => setFieldValue('from', value)}
                                  error={touched.from && errors.from}
                                  label={t('from')}
                                />
                                <ErrorMessage component={ValidationError} name="from" />
                              </div>

                              <div>
                                <div className="text-sm pb-1">
                                  {t('amount')}{' '}
                                  {errors['amount'] && <span className="text-[#EA3323]">*</span>}
                                </div>
                                <StyledInput
                                  name="amount"
                                  placeholder={t('amount')}
                                  value={values.amount}
                                  onChange={handleChange}
                                  error={touched.amount && errors.amount}
                                  prefix={currencySymbol}
                                />
                                <ErrorMessage component={ValidationError} name="amount" />
                              </div>
                            </div>

                            <div className="flex flex-col gap-4 w-full">
                              <div>
                                <div className="text-sm pb-1">
                                  {t('to')}{' '}
                                  {errors['to'] && <span className="text-[#EA3323]">*</span>}
                                </div>
                                <DatePicker
                                  name="to"
                                  value={values.to}
                                  onChange={(value) => setFieldValue('to', value)}
                                  error={touched.to && errors.to}
                                  label={t('to')}
                                />
                                <ErrorMessage component={ValidationError} name="to" />
                              </div>

                              <div>
                                <div className="text-sm pb-1">
                                  {t('payment_frequency')}{' '}
                                  {errors['paymentFrequency'] && (
                                    <span className="text-[#EA3323]">*</span>
                                  )}
                                </div>
                                <StyledSelect
                                  name="paymentFrequency"
                                  placeholder={t('payment_frequency')}
                                  value={values.paymentFrequency}
                                  onChange={handleChange}
                                  error={touched.paymentFrequency && errors.paymentFrequency}
                                  itemsList={data?.data.paymentFrequency}
                                />
                                <ErrorMessage component={ValidationError} name="paymentFrequency" />
                              </div>
                            </div>
                          </div>

                          {indexation && (
                            <div>
                              <div className="text-sm pb-1">{t('indexation_month')}</div>
                              <StyledSelect
                                name="indexationMonth"
                                placeholder={t('indexation_month')}
                                value={values.indexationMonth}
                                onChange={handleChange}
                                error={touched.indexationMonth && errors.indexationMonth}
                                itemsList={data?.data.months}
                              />
                              <ErrorMessage component={ValidationError} name="indexationMonth" />
                            </div>
                          )}

                          <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-2 ">
                              {
                                //check of values.from is less than now
                                indexation &&
                                values.from <
                                new Date(new Date().setFullYear(new Date().getFullYear())) && (
                                  <div>
                                    <div className="text-sm pb-1">{t('used_indexation')}</div>
                                    <StyledInput
                                      name="usedIndexation"
                                      placeholder="Used Indexation"
                                      value={values.usedIndexation}
                                      onChange={handleChange}
                                      error={touched.usedIndexation && errors.usedIndexation}
                                    />
                                    <ErrorMessage
                                      component={ValidationError}
                                      name="usedIndexation"
                                    />
                                  </div>
                                )
                              }
                              {
                                values.from >=
                                new Date(new Date().setFullYear(new Date().getFullYear())) && (
                                  <div className="flex gap-2 items-center">
                                    <input
                                      type="checkbox"
                                      name={t('indexation')}
                                      value={values.indexation}
                                      checked={indexation}
                                      onChange={(e) => setIndexation(e.target.checked)}
                                    />
                                    <div className="text-sm">{t('enable_indexation')}</div>
                                  </div>
                                )
                              }
                            </div>

                            <button
                              type="button"
                              className="flex gap-1 p-2 items-center justify-center rounded-lg bg-[#FFF2F0] text-[#EA3323]"
                              onClick={() =>
                                handleAddPaymentRanges({
                                  from: moment(values.from).format('YYYY-MM-DD'),
                                  to: moment(values.to).format('YYYY-MM-DD'),
                                  amount: values.amount,
                                  payment_frequency_id: values.paymentFrequency,
                                  indexation_month: indexation ? values.indexationMonth : '',
                                  enable_indexation: indexation ? 1 : 0,
                                  vat: createdClosingData?.financial.vatPercentage,
                                  used_indexation:
                                    indexation &&
                                      values.from <
                                      new Date(new Date().setFullYear(new Date().getFullYear() - 1))
                                      ? values.usedIndexation
                                      : null,
                                })
                              }
                            >
                              <img src={listingButtonIcon} />
                              {t('add_payment_range')}
                            </button>
                          </div>

                          <div className="flex flex-col gap-4 border border-[#C9C9C9] rounded-[20px] p-6 mt-6">
                            {paymentRangesArray?.length ? (
                              <div className="flex flex-col gap-5">
                                <TableHeader>
                                  {columns?.map((col) => (
                                    <div key={col.headerName}>{col.headerName}</div>
                                  ))}
                                </TableHeader>

                                <div className="flex max-h-[100px] overflow-y-auto flex-col gap-4">
                                  {paymentRangesArray?.map((row, index) => (
                                    <div>
                                      <div className="flex items-center rounded-[20px] text-sm">
                                        {columns?.map((col) => renderTableCell(col, row, index))}
                                      </div>
                                      {index !== paymentRangesArray?.length - 1 && (
                                        <div className="w-full h-[1px] bg-[#F6F4F4] mt-1"></div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <div className="flex items-center justify-center font-bold text-[#535353] text-sm">
                                {t(`no_payment_ranges`)}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </LocusContextProvider>
                  </div>

                  <div className="self-end flex gap-4">
                    {currentStep !== 0 && currentStep !== 5 && (
                      <div
                        onClick={() => handleDecreaseStep()}
                        className="flex justify-center gap-4 bg-[#F6F4F4] cursor-pointer text-[#8E8E8E] rounded-2xl font-bold p-3 w-32 text-center"
                      >
                        <img src={backArrow} />
                        {t('back')}
                      </div>
                    )}

                    {currentStep !== 5 && (
                      <StyledButton type="submit">
                        {addListingLoading || addPaymentRangeLoading || addClosingLoading ? (
                          <div className="flex justify-center items-center">
                            <CircularProgress color="inherit" size={20} />
                          </div>
                        ) : isLastStep ? (
                          t('submit')
                        ) : (
                          t('next')
                        )}
                      </StyledButton>
                    )}
                  </div>
                  <ConfirmDialog
                    open={openConfirmDialog}
                    onClose={() => setOpenConfirmDialog(false)}
                    onParentDialogClose={onClose}
                  />
                </Form>
              )}
            </Formik>
          </div>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

const StyledButton = styled.button`
  background: #ea3323;
  cursor: pointer;
  color: #fff;
  border-radius: 16px;
  min-width: 120px;
  padding: 10px;
  font-weight: bold;
`;

const ValidationError = styled.div`
  color: #ea3323;
`;

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  color: #535353;
  font-weight: 700;
  font-size: 12px;
  div {
    flex: 1;
  }
`;

const TableData = styled.div`
  flex: 1;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${({ type }) => {
    switch (type) {
      case 'For Rent':
        return '#008E0E';
      case 'For Sale':
        return '#2B5DDC';
    }
  }};
`;
