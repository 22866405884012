import React, { useEffect } from 'react';
import { LocusContext } from 'hooks/useLocusContext';
import { ErrorMessage, useFormikContext } from 'formik';
import { DatePicker, StyledAutoComplete, StyledInput, StyledSelect } from 'components';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { getGlAccounts } from 'apis/closings/closings';
import { useTranslation } from 'react-i18next';
import { StyledSearchableSelect } from 'components/styledsearchableselect';

export const ClosingDetailsDialog = () => {
  const { dropdownValues, data, dialogType } = React.useContext(LocusContext);
  const { t } = useTranslation();
  const {
    listings,
    closingTypes,
    contacts,
    progress,
    closingTypeCategories,
    vatApplicable,
    activeAccountingIntegration,
    otherCosts,
  } = dropdownValues || {};
  const { listingId, listingTypeId, propertyDetails } = data || {};
  const { values, handleChange, errors, touched, setFieldValue } = useFormikContext();
  console.log(otherCosts);
  useEffect(() => {
    console.log('values', values);

  }, [values]);

  const currencySymbol = localStorage.getItem('selectedCurrencySymbol');

  const isError = (value) => {
    if (errors[value]) return <span className="text-[#EA3323]">*</span>;
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="text-3xl font-bold">{t('closing_details')}</div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className={dialogType === 'addNewPropertyClosing' ? 'hidden' : ''}>
          <div className="text-sm pb-1">
            {t('listing')} {isError('listing')}
          </div>
          <StyledSelect
            name="listing"
            placeholder={t('listing')}
            width="100%"
            value={values.listing}
            onChange={handleChange}
            error={touched.listing && errors.listing}
            itemsList={listings}
            disabled={listingId || propertyDetails}
          />
          <ErrorMessage component={ValidationError} name="listing" />
        </div>

        <div className={dialogType === 'addNewClosing' ? 'hidden' : ''}>
          <div className="text-sm pb-1">
            {t('asking_price')} {isError('listingAskingPrice')}
          </div>
          <StyledInput
            name="listingAskingPrice"
            placeholder={t('asking_price')}
            value={values.listingAskingPrice}
            onChange={handleChange}
            error={touched.listingAskingPrice && errors.listingAskingPrice}
          />
          <ErrorMessage component={ValidationError} name="listingAskingPrice" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('offered_amount')} {isError('offeredAmount')}
          </div>
          <StyledInput
            name="offeredAmount"
            placeholder={t('offered_amount')}
            value={values.offeredAmount}
            onChange={handleChange}
            error={touched.offeredAmount && errors.offeredAmount}
            prefix={currencySymbol}
          />
          <ErrorMessage component={ValidationError} name="offeredAmount" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('effective_from')} {isError('effectiveFrom')}
          </div>
          <DatePicker
            name="effectiveFrom"
            value={values.effectiveFrom}
            onChange={(value) => setFieldValue('effectiveFrom', value)}
            error={touched.effectiveFrom && errors.effectiveFrom}
          />
          <ErrorMessage component={ValidationError} name="effectiveFrom" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('tenant')} {isError('tenant')}
          </div>
          <StyledSelect
            name="tenant"
            placeholder={t('tenant')}
            width="100%"
            value={values.tenant}
            onChange={handleChange}
            error={touched.tenant && errors.tenant}
            itemsList={contacts}
          />
          <ErrorMessage component={ValidationError} name="tenant" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('closing_type_category')} {isError('closingTypeCategory')}
          </div>
          <StyledSelect
            name="closingTypeCategory"
            placeholder={t('closing_type_category')}
            width="100%"
            value={values.closingTypeCategory}
            onChange={handleChange}
            error={touched.closingTypeCategory && errors.closingTypeCategory}
            itemsList={closingTypeCategories}
          />
          <ErrorMessage component={ValidationError} name="closingTypeCategory" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('closing_amount')} {isError('closingAmount')}
          </div>
          <StyledInput
            name="closingAmount"
            placeholder={t('closing_amount')}
            value={values.closingAmount}
            onChange={handleChange}
            error={touched.closingAmount && errors.closingAmount}
            prefix={currencySymbol}
          />
          <ErrorMessage component={ValidationError} name="closingAmount" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('closing_type')} {isError('closingType')}
          </div>
          <StyledSelect
            name="closingType"
            placeholder={t('closing_type')}
            width="100%"
            value={values.closingType}
            onChange={handleChange}
            error={touched.closingType && errors.closingType}
            itemsList={closingTypes}
            disabled={listingTypeId}
          />
          <ErrorMessage component={ValidationError} name="closingType" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('effective_to')} {isError('effectiveTo')}
          </div>
          <DatePicker
            name="effectiveTo"
            label={t('effective_to')}
            value={values.closingType === '2' ? null : values.effectiveTo}
            onChange={(value) => setFieldValue('effectiveTo', value)}
            error={touched.effectiveTo && errors.effectiveTo}
            disabled={values.closingType === '2'}
          />
          <ErrorMessage component={ValidationError} name="effectiveTo" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('status')} {isError('status')}
          </div>
          <StyledSelect
            name="status"
            placeholder={t('status')}
            width="100%"
            value={values.status}
            onChange={handleChange}
            error={touched.status && errors.status}
            itemsList={progress}
          />
          <ErrorMessage component={ValidationError} name="status" />
        </div>

        {dialogType === 'addNewPropertyClosing' && (
          <div>
            <div className="text-sm pb-1">
              {t('listing_type_category')} {isError('listingTypeCategory')}
            </div>
            <StyledSelect
              name="listingTypeCategory"
              placeholder={t('listing_type_category')}
              width="100%"
              value={values.listingTypeCategory}
              onChange={handleChange}
              error={touched.listingTypeCategory && errors.listingTypeCategory}
              itemsList={closingTypeCategories}
            />
            <ErrorMessage component={ValidationError} name="listingTypeCategory" />
          </div>
        )}

        <div>
          <div className="text-sm pb-1">
            {t('vat')} {isError('vat')}
          </div>
          <StyledSelect
            name="vat"
            placeholder={t('vat')}
            width="100%"
            value={values.vat}
            onChange={handleChange}
            error={touched.vat && errors.vat}
            itemsList={vatApplicable}
          />
          <ErrorMessage component={ValidationError} name="vat" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('due_payment_fee')} {isError('vat')}
          </div>

          <StyledInput
            name="duePaymentFee"
            placeholder={t('due_payment_fee')}
            width="100%"
            value={values.duePaymentFee}
            onChange={handleChange}
            error={touched.duePaymentFee && errors.duePaymentFee}
            prefix={currencySymbol}
          />

          <ErrorMessage component={ValidationError} name="duePaymentFee" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('due_payment_fee_days')} {isError('vat')}
          </div>

          <StyledInput
            name="duePaymentFeeDays"
            placeholder={t('due_payment_fee_days')}
            width="100%"
            value={values.duePaymentFeeDays}
            onChange={handleChange}
            error={touched.duePaymentFeeDays && errors.duePaymentFeeDays}
          />

          <ErrorMessage component={ValidationError} name="duePaymentFeeDays" />
        </div>

        <div className='col-span-2'>
          <div className="text-sm pb-1">
            {t('renting_costs')} {isError('renting_costs')}
          </div>

          <StyledInput
            name="rentingCosts"
            placeholder={t('renting_costs')}
            width="100%"
            value={values.rentingCosts}
            onChange={handleChange}
            error={touched.rentingCosts && errors.rentingCosts}
            prefix={currencySymbol}
          />

          <ErrorMessage component={ValidationError} name="rentingCosts" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('service_charges_costs')} {isError('vat')}
          </div>

          <StyledInput
            name="serviceChargesCosts"
            placeholder={t('service_charges_costs')}
            width="100%"
            value={values.serviceChargesCosts}
            onChange={handleChange}
            error={touched.serviceChargesCosts && errors.serviceChargesCosts}
            prefix={currencySymbol}
          />

          <ErrorMessage component={ValidationError} name="serviceChargesCosts" />
        </div>

        <div>
          <div className="text-sm pb-1">
            {t('up_charges_costs')} {isError('vat')}
          </div>

          <StyledInput
            name="upChargesCosts"
            placeholder={t('up_charges_costs')}
            width="100%"
            value={values.upChargesCosts}
            onChange={handleChange}
            error={touched.upChargesCosts && errors.upChargesCosts}
            prefix={currencySymbol}
          />

          <ErrorMessage component={ValidationError} name="upChargesCosts" />
        </div>

        <div className='col-span-2'>

          <div className="text-sm pb-1">
            {t('select_other_costs')} {isError('vat')}
          </div>

          <StyledSearchableSelect
            name="selectedOtherCosts"
            placeholder={t('select_other_costs')}
            width="100%"
            value={values.selectedOtherCosts}
            onChange={(selectedObjects) => {
              const updatedObjects = selectedObjects.map((obj) => ({
                ...obj,
                value: values.selectedOtherCosts.find((item) => item.id === obj.id)?.value || '', // Preserve existing values
              }));
              setFieldValue('selectedOtherCosts', updatedObjects);
            }}
            error={touched.selectedOtherCosts && errors.selectedOtherCosts}
            itemsList={otherCosts}
            multiple
          />

          <ErrorMessage component={ValidationError} name="selectedOtherCosts" />

          {values.selectedOtherCosts && (
            values.selectedOtherCosts.map((item, index) => (
              <div key={item.id} className='mt-3'>
                <div className="text-sm pb-1">
                  {`${item.name} ${t('costs')}`} {isError(`selectedOtherCosts[${index}].value`)}
                </div>

                <StyledInput
                  name={`selectedOtherCosts[${index}].value`}
                  placeholder={`${item.name} ${t('costs')}`}
                  value={item.value}
                  onChange={(e) => {
                    const updatedCosts = [...values.selectedOtherCosts];
                    updatedCosts[index].value = e.target.value;
                    setFieldValue('selectedOtherCosts', updatedCosts);
                  }}
                  error={touched.selectedOtherCosts && errors.selectedOtherCosts && errors.selectedOtherCosts[index]?.value}
                  prefix={currencySymbol}
                />

                <ErrorMessage component={ValidationError} name={`selectedOtherCosts[${index}].value`} />
              </div>
            ))
          )}

        </div>
      </div>

    </div>
  );
};

const ValidationError = styled.div`
  color: #ea3323;
`;